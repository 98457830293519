<template>
  <section class="app-ecommerce-details">            
      <div class="text-center" v-if="isLoadingProduct">							
        <b-spinner label="Loading..." variant="success"/><br>
        <strong>Loading information</strong>
      </div>     
      <b-alert variant="danger" show v-if="slugFromUrl=='product-detail'">      
        <div class="alert-body"> You have to select a product to see its detail
        </div>
      </b-alert>
      <b-card v-if="productDetail">
        <div class="float-right">
          <b-button size="sm"                     
            :to="{ name: 'sales'}" variant="warning">
            <b-icon-arrow-left></b-icon-arrow-left> Back
          </b-button>
        </div> 
        <b-card-body>          
          <b-row class="my-2">
            <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  :src="imgUrl + productDetail.coverImage"                
                  :alt="`Photo ${productDetail.name}`"
                  class="product-img"
                  fluid
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="7"
            >

              <!-- Product Name -->
              <h4>{{ productDetail.name }}</h4>

              <!-- Product Brand -->
              <b-card-text class="item-company mb-0">
                <span>Category:</span>
                <b-link class="company-name">
                  {{ productDetail.categoryName }}
                </b-link>
              </b-card-text>

              <!-- Price And Ratings -->
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">
                  $ {{ productDetail.detailSelected.saleprice }} USD
                </h4>
                <!-- <ul class="unstyled-list list-inline pl-1 border-left">
                  <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item mr-25"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="18"
                      :class="[{'fill-current': star <= productDetail.rating}, star <= productDetail.rating ? 'text-warning' : 'text-muted']"
                    />
                  </li>
                </ul> -->
              </div>

              <!-- Avability -->
              <!-- <b-card-text>Available - <span class="text-success">In stock</span></b-card-text> -->
              <!-- Product list details -->
              <b-row v-if="productDetail.detail">
                <b-col>              
                    <ChildProductsByDetail :product="productDetail"/>              
                </b-col>
              </b-row> 

              <!-- Product Description -->
              
              <b-card-text class="text-justify" v-if="isHtml">
                <div v-html="productDetail.description"></div>
              </b-card-text>
              <b-card-text class="text-justify" v-else>{{ productDetail.description }}</b-card-text>


              <div class="item-wrapper" v-if="productDetail.modelType == 'event'">
                <div class="item-rating">
                  <span><strong>Hotel:</strong> {{ productDetail.hotelName }}</span><br>
                  <span><strong>Locación:</strong> {{ productDetail.locationName }}</span><br>
                  <span><strong>Fecha inicio:</strong> {{ productDetail.startDateFormat }}</span><br>
                  <span><strong>Hora:</strong> {{ productDetail.startTimeFormat  }} - {{ productDetail.endTimeFormat  }}</span>                           
                </div>            
              </div>
                        
              <div class="d-flex flex-column flex-sm-row pt-1">
                <b-button
                  v-if="existsInCart"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  :to="{ name: 'order-summary' }"                  
                >
                  <feather-icon
                      icon="ShoppingCartIcon"
                      size="16"
                    />  View in cart
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="addProductToCart(productDetail)"
                >                  
                <feather-icon
                  icon="ShoppingCartIcon"
                  size="16"
                />  Add to cart
                </b-button>
                               
                <!-- <b-dropdown
                  variant="outline-secondary"
                  no-caret
                  toggle-class="btn-icon"
                  class="btn-share"
                  right
                >
                  <template #button-content>
                    <feather-icon icon="Share2Icon" />
                  </template>
                  <b-dropdown-item
                    v-for="icon in [ 'FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon', ]"
                    :key="icon"
                  >
                    <feather-icon :icon="icon" />
                  </b-dropdown-item>
                </b-dropdown> -->
              </div>
            </b-col>
          </b-row>
          <!-- <pre>{{productDetail}}</pre> -->
          
        </b-card-body>
      </b-card>
  </section>  
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { find } from 'lodash'
import Ripple from 'vue-ripple-directive'
import ChildProductsByDetail from '@/modules/shop/components/experiences/variants/ChildProductsByDetail'
import { overWriteAxiosHeaders } from '@/helpers/helpers'
import { addToCartDL } from '@/helpers/dataLayerHelpers'

export default {
  directives: {
    Ripple,
  },
  components: {
    ChildProductsByDetail
  },
  props:{
    slug:{
      type: String,
      required: true
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    if(!this.queryParams){
      this.$router.push({name: 'not-authorized' })
      return 
    }
    this.unsetDetailProduct()      
    this.slugFromUrl = this.$route.params.slug
    this.makePayloadFromRouteParams( this.slugFromUrl )    
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  async mounted(){
    if( this.payloadPeticion ){
      this.isLoadingProduct = true
      if(this.queryParams){
        await overWriteAxiosHeaders( this.queryParams )
        const product = await this.fetchProductDetail( this.payloadPeticion )
        this.setDetailProduct(product)  
      }
      this.isLoadingProduct = false

    }
  },
  data() {
    return {
      slugFromUrl: '',
      payloadPeticion: null,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      isLoadingProduct: false
    }
  },
  computed:{
    ...mapState('shop',['cart']),    
    ...mapState('products',['productDetail']),               
    ...mapGetters('products',['filteredProducts']), 
    ...mapState('auth',['queryParams']),               
    slugUrl() {
      return this.slugFromUrl
    },
    existsInCart() {  
      const { detailSelected }  = this.productDetail         
      const prodInCart = find(this.cart.items, { detailId: detailSelected.id, modelType: this.productDetail.modelType })
      return prodInCart ? true : false      
    },
    isHtml(){
      return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test( this.productDetail.description )       
    }          
  },
  methods: {
    ...mapActions('products',['fetchProducts','fetchProductDetail']),
    ...mapMutations('products',['unsetDetailProduct','setDetailProduct']), 
    ...mapMutations('shop',['addProductInCart']), 
    makePayloadFromRouteParams( cadena ){
      const endPoints = ["products", "events", "packages"]
      const arreglo = cadena.split("-", 2)
      const endPoint = arreglo[0]
      const idProducto = parseInt(arreglo[1])
      const isValidEndpoint = endPoints.includes(endPoint)
      if( isValidEndpoint ){
        this.payloadPeticion = { endPoint, idProducto }
      }
      return this.payloadPeticion 
    },
    addProductToCart( item ){
      const product = JSON.parse(JSON.stringify(item))               
      delete product.detail
      delete product.detailSelected.currency
      const { descmax, detaildisplay, extrapax, id, saleprice } = product.detailSelected
      product.descmax = parseInt(descmax) || 0
      product.detaildisplay = detaildisplay   
      product.extrapax = extrapax
      product.detailId = id
      product.saleprice = saleprice
      delete product.detailSelected
      if( this.$gtm.enabled() ){
        addToCartDL(product, 0)  // envio info al datalayer    
      } 
      this.addProductInCart(product)        
    },
    
  },
  watch:{
    slug(){      
      this.unsetDetailProduct()      
      this.slugFromUrl = this.$route.params.slug
      this.makePayloadFromRouteParams( this.slugFromUrl ) 
      if( this.payloadPeticion ){
        this.fetchProductDetail( this.payloadPeticion )  
      }
    }   
  }  
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>