<template>
  <b-form-group>
    <v-select class="select-size-md" :options="options" label="detaildisplay" v-model="selected" @input="setProductDetailSelected(selected)"></v-select>    
  </b-form-group>    
</template>

<script>
import {mapMutations} from 'vuex'
import vSelect from 'vue-select'    

export default {
  props:{
    product: {
      type: Object,
      required: true
    }    
  }, 
  components:{
    vSelect,
  }, 
  data(){
    return {               
      options: this.product.detail,
      selected: this.product.detailSelected.detaildisplay || this.product.detailSelected.detailDesc
    }
  },
  methods:{      
    ...mapMutations('products',['setDetailSelectedProductDetail']),            
    setProductDetailSelected( detailSelected ){
      // console.log( detailSelected )
      if( detailSelected ){
        const detalle = JSON.parse(JSON.stringify(detailSelected))                
        this.setDetailSelectedProductDetail( detalle )
      }      
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>